<template>
  <div class=" mx-auto">
    <div>
      <Loading :isLoading="this.isLoading" />
      <div>
        <div>
          <p class="title">Historial de Referidos</p>
          <p class="subtitle">
            {{history_filtered.length > 0 ? `Total de referidos ${nameStat}` : `No se encontraron registros de referidos ${nameStat}`}}
          </p>
        </div>

        <div>
          <div style="display:flex;">
            <div style="width:50%">
              <p style="font-family:Roboto;font-size:18px">Buscar por:</p>
            </div>
            <div style="margin-left:10px;width:50%">
              <select class="shadow-md border-2 rounded-lg py-2 w-full px-5 " v-model="searchBy">
                <option value="name" selected>Nombre</option>
                <option value="created_at">Fecha de invitación</option>
                <option value="status">Estatus</option>
                <!-- <option value="updated_at">Fecha de inscripción</option> -->
                <option value="email">Correo</option>
                <option value="cellphone">Celular</option>
              </select>
            </div>
          </div>

          <div style="margin-top:25px;">
            <input type="text" class="shadow-md border-2 rounded-lg py-2 w-full px-5  searchBox" style="width:100%;" placeholder="Buscar" :disabled="searchBy == ''" v-model="search" />
          </div>

          <div style="display:flex;margin-top:30px;">
            <div style="width:50%;">
              <p style="font-family:Roboto;font-size:18px">Organizar referidos por:</p>
            </div>
            <div style="margin-left:10px;width:50%;">
              <select class="shadow-md border-2 rounded-lg py-2 w-full px-5 " v-model="sortBy" v-on:change="changeSortBy(sortBy)">
                <option value="name" selected>Nombre</option>
                <option value="created_at">Fecha de invitación</option>
                <option value="status">Estatus</option>
                <!-- <option value="updated_at">Fecha de inscripción</option> -->
                <option value="email">Correo</option>
                <option value="cellphone">Celular</option>
              </select>
            </div>
          </div>
        </div>

        <div>
          <table class="table tableHistory" id="table-id" style="margin-top:20px;">
            <thead>
              <tr>
                <th>
                  Nombre
                </th>
                <th v-on:click="sortTable('rfc')">
                  Fecha de invitación
                </th>
                <th v-on:click="sortTable('name')">
                  Estatus
                </th>
                <!-- <th v-on:click="sortTable('email')">
                  Fecha de inscripción
                </th> -->
                <th>
                  
                </th>
                <th>
                  Correo
                </th>
                <th>
                  Celular
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in history_filtered" :key="item.id">
                <td>{{camelize(item.name) }}</td>
                <td>{{item.created_at}}</td>
                <td>{{item.status}}</td>
                <!-- <td>{{item.updated_at | moment}}</td> -->
                <td></td>
                <td>{{item.email}}</td>
                <td>{{item.cellphone}}</td>
              </tr>
            </tbody>
          </table>
          <div class="subTitle" v-show="history_filtered.length == 0">
            <center>
              Sin resultados coincidentes con criterios de búsqueda
            </center>
          </div>
          <div v-show="history_filtered.length > elementsPerPage" class='pagination-container' style="text-align:center;">
            <nav>
              <ul class="pagination pagination-requests">
                <li data-page="prev" style="cursor: pointer; #810042">
                  <span>Anterior<span class="sr-only">(current)</span></span>
                </li>
                <li data-page="next" id="prev" style="cursor: pointer;">
                  <span>Siguiente<span class="sr-only">(current)</span></span>
                </li>
              </ul>
            </nav>
          </div>
          <div style="display:flex !important;justify-content:center;margin-top:50px;">
            <div>
              <button class="next_button" v-on:click="goToBack()" type="button">Regresar</button>
            </div>
          </div>
        </div>
        <div class="form-group" style="visibility:hidden;"> 	<!--		Show Numbers Of Rows 		-->
          <select class ="form-control" name="state" id="maxRows">
            <option value="5000">Show ALL Rows</option>
            <option value="5">5</option>
          </select>
        </div>
      </div>
    </div>
    <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
    <!--<link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> -->
  </div>
</template>



<script>
import('@/assets/css/stylesheet.css')
import Referral from '@/classes/Referral'
import moment from "moment";

import Loading from '@/components/Loading/VueLoading.vue'

import { getPagination } from '@/helpers/tablePagination'

import('@/assets/css/tables.css')
import {  getTokenDecoden } from "@/helpers/tokenauth";


const referalMethods = {
  "get_all_by_user_id": {name: "", method: "getAllByUserId"},
  "get_sent": {name: "", method:"getSent"},
  "get_not_sent": {name: "", method: "getNoSent"},
  "get_by_account_created": { name: "con cuenta creada", method: "getWithAccountCreated" },
  "get_by_incomplete_admission_request": {name: "con solicitud incompleta", method:"getWithAdmissionRequestIncomplete"},
  "get_by_admission_request_under_review": {name: "con solicitud en revisión", method: "getWithAdmissionRequestUnderReview"},
  "get_by_admission_request_rejected": {name: "con solicitud rechazada", method: "getWithAdmissionRequestRejected"},
  "get_complete_request": {name: "inscritos", method: "getAcceptedRequest"},
  "get_by_open_links": {name: "con invitación abierta", method: "getOpenLink"}
};

export default {
    data() {
      return {
        user_id:"",
        isLoading: false,
        type: "",
        referralObjectClass: null,
        history: [],
        history_filtered: [],
        searchBy: "name",
        search: "",
        sortBy: "name",
        elementsPerPage: 10,
        nameStat: "",
        ccapi:process.env.VUE_APP_CCAPI,
      }
    },
    name: 'HistorialComponent',
    components: {
      Loading
    },
    watch: {
      search(val) {
        this.tableFilter(val).then(response => {
          getPagination("#table-id", ".pagination-requests", "#prev", "#maxRows", this.elementsPerPage, this.history_filtered.length);
        })
      },
      searchBy(val) {
        this.search = "";
      }
    },
    mounted() {
      this.type = this.$route.params.type
      this.referralObjectClass = new Referral();
      this.getHistory().then(response => {
        getPagination("#table-id", ".pagination-requests", "#prev", "#maxRows", this.elementsPerPage, this.history_filtered.length);
        this.sort(this.sortBy).then(response => {
          getPagination("#table-id", ".pagination-requests", "#prev", "#maxRows", this.elementsPerPage, this.history_filtered.length);
        });
      })
    },
    async beforeMount() {
      await this.loadInfo();
    },
    methods:{
      async tableFilter(val) {
        let listFiltered = [];

        if (val != "") {
          listFiltered = this.history.filter(b => b[this.searchBy].toUpperCase().includes(val.toUpperCase()));
        } else {
          listFiltered = this.history;
        }

        this.history_filtered = listFiltered;
      },
      getHistory() {
        console.log("funcion: ", referalMethods[this.type]);
        console.log("user id: ", this.user_id);
        this.nameStat = referalMethods[this.type].name;
        console.log("name: ", this.nameStat);
        return this.referralObjectClass[referalMethods[this.type].method](sessionStorage.getItem("login"), this.user_id).then(response => {
          if (response.data.response.data.length > 0) {
            let historyData = this.createArrayObjectHistory(response.data.response.data);
            this.history = historyData;
            this.history_filtered = historyData;
          }
          console.log("response request:", this.history_filtered);
        }).catch(error => {

        });
      },
      createArrayObjectHistory(data) {
        let newData = data.map(x => ({
          cellphone: x.cellphone,
          created_at: this.momentDate(x.created_at),
          email: x.email,
          expiration_date: x.expiration_date,
          fonsnte_user_id: x.fonsnte_user_id,
          id: x.id,
          name: x.name,
          reference_code: x.reference_code,
          referenced_user_id: x.referenced_user_id,
          status: x.status,
          total_sent: x.total_sent,
          updated_at: x.updated_at
        }));

        return newData;
      },
      momentDate(date) {
        return moment(date).format("DD/MM/YYYY");
      },
      Logout(){
        sessionStorage.clear();
        this.$router.push({path:"/"});
        // window.location.href = "/"
      },
      async loadInfo() {
        var auth = getTokenDecoden();
        this.user_id = auth["id"];
      },
      changeSortBy(col) {
        this.sort(col).then(response => {
          getPagination("#table-id", ".pagination-requests", "#prev", "#maxRows", this.elementsPerPage, this.history_filtered.length);
        });
      },
      async sort(col) {
        if (this.sortColumn === col) {
          this.ascending = !this.ascending;
        } else {
          this.ascending = true;
          this.sortColumn = col;
        }

        var ascending = this.ascending;

        this.history_filtered.sort(function(a, b) {
          if (a[col] > b[col]) {
            return ascending ? 1 : -1
          } else if (a[col] < b[col]) {
            return ascending ? -1 : 1
          }
          return 0;
        })
      },
      goToBack() {
        // window.location.href = "/cuenta/referidos"
        this.$router.push({path:"/cuenta/referidos"});
      },
      camelize(str) {
        let response = ""
        if (str != "" && str != undefined) {
          str = str.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, function(match) {
            return match.charAt(match.length-1).toUpperCase();
          });
          response = str.charAt(0).toUpperCase() + str.substring(1);
          return response.replace(/([A-Z])/g, ' $1').trim(); 
        }
        return "";
      },
      momentFormat(date) {
        return moment(date).format("DD/MM/YYYY");
      },
    }
}
</script>


<style>

#button_popups {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background-color: #feb72b;
  color: #fff;
  font-weight:bold;
  width: 250px;
  height: 40px;
  padding-top: 0%;
  font-size:16px;
}

.title {
  font-size:30px;
  font-family:Roboto;
  color: #BFD243;
  font-weight: 500;
  margin-top:0px;
  text-align:left;
}
.description {
  font-size:20px;
  font-family:Roboto;
  color: #000;
  font-weight: 300;
  margin-top:0px;
  text-align:left;
}
.subtitle {
  font-size:20px;
  font-family:Roboto;
  color: #000;
  font-weight: 300;
  text-align:left;
}

.inputForm {
  border:1px solid #bfd243;
  text-transform: uppercase;
  margin-top:-0.5px;
  width:100%;
  height: 40px;
  padding:5px;
  border:1px solid #bfd243;margin-top:-5px; 
  border-radius: 3px;
}

.historyContainer {
  border: 1px solid #bfd243;
  padding:10px;
}

.nameRefer {
  font-family: Roboto;
  font-weight: 500;
  font-size: 15px;
}

.info {
  font-family: Roboto;
  font-weight: 300;
  font-size: 15px;
}

.infoValue {
  font-family: Roboto;
  font-weight: 400;
  font-size: 15px;
}

.searchBox {
  background-image:url('../../../assets/Analyst/search.svg') !important;
  background-position: 99.5% 50% !important;
  background-size: 25px;
  background-repeat:no-repeat !important;
  outline-offset: 2px;
}

/*Bootstrap*/

.pagination>.active>a, .pagination>.active>a:focus, 
.pagination>.active>a:hover, .pagination>.active>span, 
.pagination>.active>span:focus, .pagination>.active>span:hover {
  background-color: #feb72b !important;
  border-color: #feb72b !important;
  color: #fff !important;
}

.pagination>li>a, .pagination>li>span {
  color: #feb72b !important;
  border: 1px solid #feb72b !important;
}

input[type="text"]:focus-visible,
select:focus-visible{
  border: 2px solid #810042 ;
  outline: none;
}

</style>